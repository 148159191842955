import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import LogRocket from 'logrocket'

dayjs.extend(utc)
dayjs.extend(duration)
/*if (typeof window !== "undefined") {
  if (window?.location.host!=="localhost:3000"){
    LogRocket.init('bh6dw6/deepspace', {
      release: '0.0.4',
      rootHostname: 'deepspace.game',
    })
  }
}*/



export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>DEEPSPACE Bridge</title>
      </Head>
      <Component {...pageProps} />
    </>
  )
}
